import AuthApi from "@/utils/api/auth";
import { Alert, Button, Col, Form, Input, Space, Typography } from "antd";
import { Formik } from "formik";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { BiLinkExternal } from "react-icons/bi";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import useApi from "../../hooks/useApi";
import useApiState from "../../hooks/useApiState";

const QueryError = ["url", "user", "token"];

const ResetPassword = () => {
  const { t } = useTranslation("common", { keyPrefix: "resetPassword" });

  /* ----------- Invalid Redirect Link ---------- */
  const [searchParams] = useSearchParams();
  const queryError = searchParams.get("error");
  const showQueryError = queryError ? QueryError.includes(queryError) : false;

  /* -------------------- API ------------------- */
  const api = useApi(AuthApi, { useGlobalScope: true });
  const apiResetPassword = useApiState(api.resetPasswordEmail);

  /* ------------------- Form ------------------- */
  const schema = Yup.object().shape({
    email: Yup.string()
      .email(t("error.invalidEmail"))
      .required(t("error.requireEmail")),
  });
  const [validateOnChange, setValidateOnChange] = useState(false);
  const onSubmit = async (values: any) => {
    await apiResetPassword.send(values.email);
  };

  return (
    <>
      {!showQueryError && (
        <Col xs={24}>
          {<p style={{ textAlign: "center" }}>{t("information")}</p>}
        </Col>
      )}

      {/* Alert Message on Redirect */}
      {showQueryError && apiResetPassword.success === undefined && (
        <Col xs={24}>
          <Alert
            description={t(`error.link.${queryError}.alertBody`)}
            type={"error"}
            showIcon
          />
        </Col>
      )}

      {/* Alert Message on Submit */}
      <Col xs={24}>
        {apiResetPassword.success !== undefined &&
          (apiResetPassword.success ? (
            <Alert
              message={t("success.submit.alertTitle")}
              description={t("success.submit.alertBody")}
              type={"success"}
              showIcon
            />
          ) : (
            <Alert
              message={t("error.submit.alertTitle")}
              description={
                <Trans
                  ns={"common"}
                  i18nKey={"resetPassword.error.submit.alertBody"}
                  components={{
                    Link: (
                      <Typography.Link
                        href="https://www.interagent.io/"
                        target="_blank"
                        underline
                      />
                    ),
                    Icon: <BiLinkExternal />,
                  }}
                  values={{ interagentLink: "Interagent" }}
                />
              }
              type={"error"}
              showIcon
            />
          ))}
      </Col>

      {/* Input Email */}
      <Col xs={24}>
        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={onSubmit}
          validationSchema={schema}
          validateOnChange={validateOnChange}
          validateOnBlur={validateOnChange}
        >
          {(formik) => (
            <Form
              onFinish={() => {
                setValidateOnChange(true);
                formik.handleSubmit();
              }}
            >
              <Form.Item
                validateStatus={formik.errors.email ? "error" : "success"}
                help={formik.errors.email}
              >
                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  <Button
                    type={"primary"}
                    htmlType={"submit"}
                    danger={!!formik.errors.email}
                    loading={formik.isSubmitting}
                  >
                    {t("send")}
                  </Button>
                </Space.Compact>
              </Form.Item>
            </Form>
          )}
        </Formik>
      </Col>
    </>
  );
};

export default ResetPassword;
