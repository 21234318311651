/**
 * @note Typing is provided in `src/@types/styled.d.ts` to overwrite the default theme.
 */

import useTheme from "@/hooks/useTheme";
import { theme } from "antd";
import React from "react";
import { ThemeProvider } from "styled-components";

const SThemeProvider = ({ children }: { children: React.ReactNode }) => {
  const { token } = theme.useToken();
  const { dark } = useTheme();
  return (
    <ThemeProvider theme={{ antd: token, dark }}>{children}</ThemeProvider>
  );
};

export default SThemeProvider;
