import { ApiService } from "../axios";

/** Types */
import { EVENT, Levels } from "../../types/notification";

const endpoints = {
  sync: {
    url: "/api/notification/sync",
    method: "post",
  },
  read: {
    url: "/api/notification/read",
    method: "post",
  },
  resetLevel: {
    url: "/api/notification/reset-level",
    method: "post",
  },
} as const;

type EndpointName = keyof typeof endpoints;
interface ApiSyncResponse {
  messages: {
    created_on: string;
    id: number;
    level: Levels;
    subject: string;
    body: string;
  }[];
  last_message_id: number;
  last_count: number;
  last_level: Levels;
  last_reset_id: number;
  last_idle_status: false;
  last_idle_id: number;
  last_read_id: number;
  pg_name: string;
  pg_perc: 10;
}

class NotificationApi extends ApiService<EndpointName> {
  _endpoints = endpoints;

  async sync({
    event,
    limit,
  }: {
    event: EVENT;
    limit: number;
  }): Promise<ApiSyncResponse | null> {
    const response = await this.send("sync", {
      data: { event, limit },
    });
    return response.ok ? response.data : null;
  }

  async read({ event, id }: { event: EVENT; id: number }): Promise<Boolean> {
    const response = await this.send("read", {
      data: { event, id },
    });

    return response.ok;
  }

  async resetLevel({ event }: { event: EVENT }): Promise<Boolean> {
    const response = await this.send("resetLevel", {
      data: { event },
    });

    return response.ok;
  }
}

export default NotificationApi;
