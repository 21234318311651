/**
 * !Don't lazy load components with <React.Suspense />.
 */

/* ------------------ Imports ----------------- */
import { lazy } from "react";
import { Navigate } from "react-router";
import { Link } from "react-router-dom";
import FallbackError from "./components/FallbackError";
import type { ExtRouteObject } from "./types/route";

/* ---------------- Componenets --------------- */
/** Authentication */
import ResetPasswordForm from "./components/auth/ResetPasswordForm";
import ResetPasswordRequest from "./components/auth/ResetPasswordRequest";
import AuthGuard from "./components/guards/AuthGuard";
import GuestGuard from "./components/guards/GuestGuard";
import HomeGuard from "./components/guards/HomeGuard";
import InitGuard from "./components/guards/InitGuard";
import AuthLayout from "./layouts/Auth";
import Page404 from "./pages/auth/Page404";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import SignInPage from "./pages/auth/SignInPage";

/** Root */
import RestoreLanguage from "./components/language/RestoreLanguage";
import RootLayout from "./layouts/Root";

/* ---------------- Lazy Import --------------- */
/** React-router Bugs
 * @suspense_not_working When use the lazy property, the suspense won't be triggered. Possibly due to promise is not bubbled up?
 */

/** Home */
const HomeLayout = lazy(
  /* webpackChunkName: "HomeLayout" */
  () => import("./layouts/Home")
);
const HomePage = lazy(
  /* webpackChunkName: "HomePage" */
  () => import("./pages/root/home/HomePage")
);

/** Process */
const ProcessListPage = lazy(
  /* webpackChunkName: "ProcessListPage" */
  () => import("./pages/root/process/ProcessListPage")
);
const ProcessTimeAnalysis = lazy(
  () => import("./pages/root/process/TimeAnalysis")
);
const EditProcess = lazy(() => import("./pages/root/process/EditProcess"));
const NewProcess = lazy(() => import("./pages/root/process/NewProcess"));

/** Report */
const ReportLayout = lazy(() => import("./layouts/Report"));
const ReportIndex = lazy(() => import("./pages/root/report/Index"));

/** Version */
const VersionsPage = lazy(
  () =>
    import(
      /* webpackChunkName: "VersionsPage" */
      "./pages/root/version/VersionPage"
    )
);
const EditPage = lazy(
  () =>
    import(
      /* webpackChunkName: "VersionEditPage" */
      "./pages/root/version/EditPage"
    )
);

/** Workcenter */
const WorkcenterUploadWip = lazy(
  () => import("./pages/root/workcenter/UploadWip")
);
const WorkcenterSchedule = lazy(
  () => import("./pages/root/workcenter/Schedule")
);

/** Resource */
const ResourceDetail = lazy(
  () =>
    import(
      /* webpackChunkName: "ResourceDetail" */
      "./pages/root/resource/ResourceDetail"
    )
);
const ResourceGantt = lazy(
  () =>
    import(
      /* webpackChunkName: "ResourceGantt" */
      "./pages/root/resource/Gantt"
    )
);

/** Project */
const ProjectDelay = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectDelay" */
      "./pages/root/project/Delay"
    )
);
const ProjectAdvancedDelay = lazy(
  /* webpackChunkName: "ProjectAdvancedDelay" */
  () => import("./pages/root/project/AdvancedDelay")
);
const ProjectGantt = lazy(
  () =>
    import(
      /* webpackChunkName: "ProjectGantt" */
      "./pages/root/project/Gantt"
    )
);
const ProjectManagement = lazy(
  /* webpackChunkName: "ProjectManagement" */
  () => import("./pages/root/project/ProjectManagementPage")
);
const ProjectClosed = lazy(
  /* webpackChunkName: "ProjectClosed" */
  () => import("./pages/root/project/ProjectClosedPage")
);
const NewProject = lazy(
  /* webpackChunkName: "NewProject" */
  () => import("./pages/root/project/NewProjectPage")
);
const ProjectProgress = lazy(
  /* webpackChunkName: "ProjectProgressPage" */
  () => import("./pages/root/project/ProjectProgressPage")
);

/** Setting */
const SettingLayout = lazy(() => import("./layouts/Setting"));
const UsersPage = lazy(() => import("./pages/setting/UsersPage"));
const NewUser = lazy(() => import("./pages/setting/NewUser"));
const EditUser = lazy(() => import("./pages/setting/EditUser"));
const ChangeUserPassword = lazy(
  () => import("./pages/setting/ChangeUserPassword")
);
const LanguagePage = lazy(() => import("./pages/auth/LanguagePage"));
const WorkingCalendarPage = lazy(
  () => import("./pages/setting/WorkingCalendarPage")
);
const WorkingDayPage = lazy(() => import("./pages/setting/WorkingDayPage"));
const WorkingHourPage = lazy(() => import("./pages/setting/WorkingHourPage"));
const AbnormalPage = lazy(() => import("./pages/setting/AbnormalPage"));
const DefaultValuesPage = lazy(
  () => import("./pages/setting/DefaultValuesPage")
);
const CronJobsPage = lazy(() => import("./pages/setting/CronJobsPage"));
const MailNotif = lazy(() => import("./pages/setting/MailNotifPage"));
const NewMailNotif = lazy(() => import("./pages/setting/NewMailNotifPage"));
const EditMailNotif = lazy(() => import("./pages/setting/EditMailNotifPage"));
const MultiEditMailNotif = lazy(
  () => import("./pages/setting/MultiEditMailNotifPage")
);
/** Customer-Specific Customized Pages */
const CustomizedEntry = lazy(() => import("./customized/Entry"));

/** ---------- Routes ---------- */
const routes: ExtRouteObject[] = [
  {
    path: "/auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        /** prevent empty auth page */
        index: true,
        element: <Navigate to="/auth/sign-in" />,
      },
      {
        /** use GuestGuard to redirect authenticated user to `/` */
        path: "sign-in",
        element: <SignInPage />,
      },
      {
        path: "reset-password",
        element: <ResetPasswordPage />,
        children: [
          {
            index: true,
            element: <ResetPasswordRequest />,
          },
          {
            path: ":token",
            element: <ResetPasswordForm />,
          },
        ],
      },
      {
        path: "language",
        element: <LanguagePage isLogin={false} />,
      },
    ],
  },
  {
    path: "/",
    errorElement: <FallbackError />,
    element: (
      /** use AuthGuard to redirect unauthenticated user to `SignIn` */
      <AuthGuard>
        <RestoreLanguage>
          <RootLayout />
        </RestoreLanguage>
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: (
          <HomeGuard>
            <HomeLayout>
              <HomePage />
            </HomeLayout>
          </HomeGuard>
        ),
        //! Don't specify role here
      },
      {
        /**
         * ! When changing path, it should be reflected to SSR pages that referenced this route.
         */
        path: "process",
        role: ["admin"],
        handle: {
          crumb: () => <Link to="/process">製程列表</Link>,
        },
        children: [
          {
            index: true,
            element: <ProcessListPage />,
          },
          // {
          //   path: "time-analysis",
          //   element: <ProcessTimeAnalysis />,
          // },
          {
            path: "new",
            element: <NewProcess />,
            handle: {
              crumb: () => <span>新增製程</span>,
            },
          },
          {
            path: "edit/:id",
            handle: {
              crumb: (params: any) => {
                const { id } = params;
                return <Link to={`/process/edit/${id}`}>編輯製程</Link>;
              },
            },
            children: [
              {
                index: true,
                element: <EditProcess />,
              },
            ],
          },
        ],
      },
      {
        path: "resource",
        role: ["admin"],
        handle: {
          //! Switch to SSR page
          crumb: () => <a href="/resource/manage">資源管理</a>,
        },
        children: [
          {
            index: true,
            element: <></>,
          },
          {
            path: ":id",
            handle: {
              crumb: () => <span>檢視資源</span>,
            },
            element: <ResourceDetail />,
          },
          {
            path: "gantt",
            element: <ResourceGantt />,
          },
        ],
      },
      {
        path: "project",
        role: ["admin"],
        handle: {
          crumb: () => <Link to="/project">專案管理</Link>,
        },
        children: [
          {
            index: true,
            element: <ProjectManagement />,
          },
          {
            path: "closed",
            element: <ProjectClosed />,
          },
          {
            path: "delay",
            handle: {
              crumb: () => <Link to="/project/delay">逾時分析</Link>,
            },

            children: [
              {
                index: true,
                element: <ProjectDelay />,
              },
              {
                path: ":id",
                handle: {
                  crumb: () => <span>進階分析</span>,
                },
                element: <ProjectAdvancedDelay />,
              },
            ],
          },
          {
            path: "gantt",
            element: <ProjectGantt />,
          },
          {
            path: "new",
            element: <NewProject />,
          },
          {
            path: "progress",
            element: <ProjectProgress />,
          },
        ],
      },
      {
        path: "report",
        role: ["admin"],
        element: <ReportLayout />,
        children: [
          {
            index: true,
            element: <ReportIndex />,
          },
        ],
      },
      {
        path: "versions",
        role: ["admin"],
        handle: { crumb: () => <Link to="/versions">版次紀錄</Link> },
        children: [
          {
            index: true,
            element: <VersionsPage />,
          },
          {
            path: ":id",
            element: <EditPage />,
            handle: { crumb: () => <Link to="/versions/:id">版次資訊</Link> },
          },
        ],
      },
      {
        path: "workcenter",
        children: [
          {
            index: true,
            element: <Navigate to="/workcenter/schedule" />,
          },
          {
            path: "upload-wip",
            element: <WorkcenterUploadWip />,
          },
          {
            path: "schedule",
            element: <WorkcenterSchedule />,
          },
        ],
      },
      {
        path: "setting",
        role: ["admin"],
        element: <SettingLayout />,
        handle: {
          crumb: () => <span>設定</span>,
        },
        children: [
          {
            path: "users",
            handle: {
              crumb: () => <Link to="/setting/users">使用者管理</Link>,
            },
            children: [
              {
                index: true,
                element: <UsersPage />,
              },
              {
                path: "new-user",
                element: <NewUser />,
                handle: {
                  crumb: () => (
                    <Link to="/setting/users/new-user">新增使用者</Link>
                  ),
                },
              },
              {
                path: "edit-user/:id",
                handle: {
                  crumb: (params: any) => {
                    const { id } = params;
                    return (
                      <Link to={`/setting/users/edit-user/${id}`}>
                        編輯使用者
                      </Link>
                    );
                  },
                },
                children: [
                  {
                    index: true,
                    element: <EditUser />,
                  },
                  {
                    path: "change-password",
                    element: <ChangeUserPassword />,
                    handle: {
                      crumb: () => <span>變更密碼</span>,
                    },
                  },
                ],
              },
            ],
          },
          {
            path: "email-notif",
            handle: {
              crumb: () => <Link to="/setting/email-notif">信箱通知</Link>,
            },

            children: [
              { index: true, element: <MailNotif /> },
              {
                path: "new",
                element: <NewMailNotif />,
                handle: {
                  crumb: () => <span>新增收件人</span>,
                },
              },
              {
                path: "edit/multi",
                element: <MultiEditMailNotif />,
                handle: {
                  crumb: () => <span>批次編輯收件人</span>,
                },
              },
              {
                path: "edit/:id",
                element: <EditMailNotif />,
                handle: {
                  crumb: () => <span>編輯收件人</span>,
                },
              },
            ],
          },
          {
            path: "workingcalendar",
            handle: {
              crumb: () => (
                <Link to="/setting/workingcalendar">廠區行事曆</Link>
              ),
            },
            element: <WorkingCalendarPage />,
          },
          {
            path: "workingday",
            handle: {
              crumb: () => <Link to="/setting/workingday">工作日</Link>,
            },
            element: <WorkingDayPage />,
          },
          {
            path: "workinghour",
            handle: {
              crumb: () => <Link to="/setting/workinghour">工作班表</Link>,
            },
            element: <WorkingHourPage />,
          },
          {
            path: "abnormal",
            handle: {
              crumb: () => <Link to="/setting/abnormal">異常代碼</Link>,
            },
            element: <AbnormalPage />,
          },
          {
            path: "default-values",
            handle: {
              crumb: () => <Link to="/setting/default-values">系統預設值</Link>,
            },
            element: <DefaultValuesPage />,
          },
          {
            path: "cronjobs",
            handle: {
              crumb: () => <Link to="/setting/cronjobs">自動重排</Link>,
            },
            element: <CronJobsPage />,
          },
        ],
      },
      {
        path: "customized/*",
        element: <CustomizedEntry />,
        role: ["admin"],
      },

      /** This is temporaily route. Put it under setting later. */
      {
        path: "language",
        element: <LanguagePage isLogin={true} />,
      },
    ],
  },
  {
    path: "*",
    element: (
      <InitGuard>
        <AuthLayout />
      </InitGuard>
    ),
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
] as ExtRouteObject[];

export default routes;
