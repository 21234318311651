import dayjs from "dayjs";
import { saveAs } from "file-saver";
import formatDuration from "format-duration";
import type { EnhancedAxiosResponse } from "./axios";

interface SortedIndexParams<T> {
  array: T[];
  value: T;
  selector?: (item: T) => any;
  direction?: "asc" | "desc";
  returnNullifDuplicate?: boolean;
}

export function sortedIndex<T>({
  array,
  value,
  selector = (v) => v,
  direction = "asc",
  returnNullifDuplicate = false,
}: SortedIndexParams<T>) {
  let low: number = 0;
  let high: number = array.length;
  let mid: number;

  while (low < high) {
    /** Instead of using `(low + high) / 2`, below prevents overflow. */
    mid = Math.floor(low + (high - low) / 2);

    const refValue = selector(array[mid]);
    const insertedValue = selector(value);

    if (returnNullifDuplicate && refValue === insertedValue) return null;

    if (
      direction === "asc" ? refValue < insertedValue : refValue > insertedValue
    ) {
      low = mid + 1;
    } else {
      high = mid;
    }
  }
  return low;
}

export function getKeyByValue(
  value: string | number | boolean | undefined | null,
  enumObj: any
) {
  return Object.keys(enumObj).find((key) => enumObj[key] === value);
}

export function formatInterval(
  value: number,
  inputUnit: "s" | "m" | "h" | "d",
  i18n: {
    s?: string;
    m?: string;
    h?: string;
    d?: string;
  },
  config?: {
    startUnit?: "s" | "m" | "h" | "d";
    maxUnitCount?: number;
  }
) {
  //! Change default value with caution.
  const startUnit = config?.startUnit || "s";
  const maxUnitCount = config?.maxUnitCount || 2;

  /** Ignore zero value and null value */
  if (!value) return;

  /** convert duration to milliseconds */
  let duration;
  switch (inputUnit) {
    case "s":
      duration = formatDuration(value * 1000);
      break;
    case "m":
      duration = formatDuration(value * 60 * 1000);
      break;
    case "h":
      duration = formatDuration(value * 60 * 60 * 1000);
      break;
    case "d":
      duration = formatDuration(value * 60 * 60 * 24 * 1000);
      break;

    default:
      throw new Error(inputUnit + " unit is not supported.");
  }

  /**
   * @note `d:h:m:s`. Larger unit at left.
   * @note `s` is the smallest unit.
   * @note Both `m` and `s` always exist as `m:s`.
   */

  const unitInfo = [
    {
      unit: "d",
      unitLabel: i18n.d || "d",
    },
    {
      unit: "h",
      unitLabel: i18n.h || "h",
    },
    {
      unit: "m",
      unitLabel: i18n.m || "m",
    },
    {
      unit: "s",
      unitLabel: i18n.s || "s",
    },
  ];
  let durationSplit = duration.split(":");

  const unitInfoWithTime = unitInfo
    .slice(unitInfo.length - durationSplit.length, unitInfo.length)
    .map((info, index) => {
      const value = parseInt(durationSplit[index]);
      return {
        ...info,
        value,
        label: `${value} ${info.unitLabel}`,
      };
    });

  const text = unitInfoWithTime
    .slice(0, unitInfoWithTime.findIndex((i) => i.unit === startUnit) + 1) // finding the minimum unit
    .slice(0, maxUnitCount) // limit numbers of units
    .filter((i) => i.value !== 0) // ignore unit with zero value (keep both negative and positive values)
    .map((i) => i.label)
    .join(" ");

  if (!text)
    return "< 1 " + unitInfo.find((i) => i.unit === startUnit)?.unitLabel;

  return text;
}

export function getFilenameFromHeader(header: string | undefined) {
  const match = header && header.match(/filename=(.+)$/);
  if (match) {
    /** Remove Quotes from String */
    return match[1].replace(/['"]+/g, "");
  }
  return "download.csv";
}

export function saveCsvFromAxios({
  response,
  onSuccess,
  onError,
}: {
  response: EnhancedAxiosResponse;
  onSuccess?: Function;
  onError?: Function;
}) {
  if (response?.ok) {
    const filename = getFilenameFromHeader(
      response.headers["content-disposition"]
    );
    const BOM = "\uFEFF";
    const blob = new Blob([BOM + response.data], {
      type: "text/csv;charset=utf-8-sig;",
    });

    saveAs(blob, filename);
    onSuccess && onSuccess();
  } else {
    onError && onError();
  }
}

export class JsonToCsvParser {
  _delimiter: string;
  _newline: string;
  _quoteChar: string;
  _escapedQuote: string;
  _delimiterCharRegex: InstanceType<RegExpConstructor>;
  _quoteCharRegex: InstanceType<RegExpConstructor>;

  #_byte_order_mark: string;
  #_bad_delimiters: string[];

  constructor() {
    this._delimiter = ",";
    this._newline = "\r\n";
    this._quoteChar = '"';
    this._escapedQuote = this._quoteChar + this._quoteChar;
    this._delimiterCharRegex = new RegExp(
      this.#escapeRegExp(this._delimiter),
      "g"
    );
    this._quoteCharRegex = new RegExp(this.#escapeRegExp(this._quoteChar), "g");

    this.#_byte_order_mark = "\ufeff";
    this.#_bad_delimiters = ["\r", "\n", '"', this.#_byte_order_mark];
  }

  /** https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions */
  #escapeRegExp(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  #hasAnyBadDelimiters(str: string) {
    return this.#_bad_delimiters.some((d) => str.indexOf(d) > -1);
  }

  #safeValue(value: any, customizedFn?: (v: any) => string) {
    if (value === undefined || value === null) return "";
    const str = customizedFn ? customizedFn(value) : String(value);

    let needsQuotes =
      this._delimiterCharRegex.test(str) || this.#hasAnyBadDelimiters(str);

    if (needsQuotes) {
      const escapedQuoteStr = str.replace(
        this._quoteCharRegex,
        this._escapedQuote
      );
      return this._quoteChar + escapedQuoteStr + this._quoteChar;
    }

    return str;
  }

  download({
    data,
    filename,
    i18n_header,
    ignoreCols = [],
    customizedCols = {},
  }: {
    data: {}[] | undefined;
    filename: string;
    i18n_header?: Record<string, string>;
    ignoreCols?: string[];
    customizedCols?: any;
  }) {
    if (!data) return;

    const firstItem = data[0];
    if (!firstItem) return; // ignore if no keys are found
    let csv = "";
    /** Write headers */
    const columns = Object.keys(firstItem).filter(
      (col) => !ignoreCols?.includes(col)
    );
    if (columns.length > 0) {
      columns.forEach((value, index) => {
        if (index > 0) csv += this._delimiter;
        const i18n_value = (i18n_header && i18n_header[value]) || value;
        csv += this.#safeValue(i18n_value);
      });
      if (csv.length > 0) csv += this._newline;
    }

    /** Write content */
    data.forEach((row: Record<any, any>) => {
      columns.forEach((column, index) => {
        if (index > 0) csv += this._delimiter;
        csv += this.#safeValue(row[column], customizedCols[column]);
      });
      csv += this._newline;
    });

    const blob = new Blob([this.#_byte_order_mark + csv], {
      type: "text/csv;charset=utf-8-sig;",
    });
    saveAs(
      blob,
      dayjs().format("YYYY-MM-DD-HHmmss") +
        "__" +
        (filename || "download") +
        ".csv"
    );
  }
}

export function copyToClipboard({
  text,
  onSuccess,
}: {
  text: string;
  onSuccess: () => void;
}) {
  try {
    navigator.clipboard.writeText(text).then(
      () => onSuccess && onSuccess(),
      (err) => {
        console.error(err);
      }
    );
  } catch (e) {
    console.error(e);
  }
}
