import NotificationApi from "@/utils/api/notification";
import { Popover } from "antd";
import classNames from "classnames";
import { BiErrorAlt } from "react-icons/bi";
import useApi from "../../../hooks/useApi";
import useAppSelector from "../../../hooks/useAppSelector";
import { selectAbnormalMessage } from "../../../redux/slices/notification";
import { EVENT } from "../../../types/notification";
import logger from "../../../utils/logger";
import Tag from "../../ui/Tag";
import S from "../Header.module.scss";
import DropdownContent from "./DropdownContent";
import { getBsColorClass_ByLevel, renderMessageCount } from "./funcs";

const renderBody = (data: string) => {
  try {
    const obj = JSON.parse(data) as {
      resource?: string;
      mo_nbr?: string;
      product_name?: string;
      lot_nbr?: string;
      workcenter_name?: string;
      sourcing?: string;
      job_name?: string;
    };
    if (obj.hasOwnProperty("resource")) {
      return <Tag variant="yellow">{obj.resource}</Tag>;
    } else {
      return (
        <div className="d-flex flex-wrap gap-1">
          <Tag>{obj.mo_nbr}</Tag>
          <Tag>{obj.product_name}</Tag>
          <Tag>{obj.lot_nbr}</Tag>
          <Tag variant="cyan">{obj.workcenter_name}</Tag>
          <Tag variant="cyan">{obj.sourcing}</Tag>
          <Tag variant="cyan">{obj.job_name}</Tag>
        </div>
      );
    }
  } catch (e) {
    logger.error(e);
    return data;
  }
};

/** ---------- Code Start ---------- */
const NotifAbnormalMessage = () => {
  const message = useAppSelector(selectAbnormalMessage);
  const api = useApi(NotificationApi);

  const bgBsClass = getBsColorClass_ByLevel(message.level, "bg");
  const textBsClass = getBsColorClass_ByLevel(message.level, "text");

  const toggleRead = () => {
    if (message.data.length > 0) {
      api.read({ event: EVENT.ABNOMRAL, id: message.data[0].id });
      api.resetLevel({ event: EVENT.ABNOMRAL });
    }
  };

  const popoverContent = (
    <DropdownContent
      message={message}
      hrefShowAll={"/notification/history?event=abnormal&limit=50"}
      toggleRead={toggleRead}
      renderBody={renderBody}
      extraFooter={
        <a href="/abnormal-ongoing" className="text-muted">
          異常紀錄
        </a>
      }
    />
  );

  return (
    <Popover trigger="click" content={popoverContent}>
      <button className={S.notifBtn}>
        <BiErrorAlt className={textBsClass} />
        <span className={classNames(S.notifCount, bgBsClass)}>
          {renderMessageCount(message.count)}
        </span>
      </button>
    </Popover>
  );
};

export default NotifAbnormalMessage;
