import { useEffect, useState } from "react";
/** Antd */
import { Avatar, Button, Drawer, List, Space, Switch, theme } from "antd";

/** Icons */
import {
  AiOutlineClose,
  AiOutlineLeft,
  AiOutlineRight,
  AiFillCaretDown,
} from "react-icons/ai";
import { BiMenu } from "react-icons/bi";
import { CgMenuGridR } from "react-icons/cg";
import { BsLightningChargeFill, BsMoonStars, BsSun } from "react-icons/bs";
import { FiLogOut } from "react-icons/fi";
import { FaUserCircle } from "react-icons/fa";

/** Components */
import ProgressBar from "./ProgressBar";
import FunctionButton from "./FunctionButton";
import UserAccount from "./UserAcount";
import Menu from "./Menu";

/** Hooks */
import useTheme from "../../hooks/useTheme";
import useAuth from "../../hooks/useAuth";
import useMedia from "../../hooks/useMedia";

/** Plugins */
import styled from "styled-components";
import Shortcut from "./Shortcut";
import { useLocation } from "react-router-dom";

const { useToken } = theme;

/* ------------------- Types ------------------ */
type ChildrenDrawerStatus = {
  [key: string]: boolean;
};

/* ---------------- Code Starts --------------- */

const initialStatus = {
  account: false,
  shortcus: false,
  service: false,
};

const MobileMenu = () => {
  const [open, setOpen] = useState(false);
  const [childrenDrawerStatus, setChildrenDrawerStatus] =
    useState<ChildrenDrawerStatus>(initialStatus);

  const { changeTheme, dark } = useTheme();
  const { signOut } = useAuth();
  const { xs } = useMedia();
  const { pathname } = useLocation();
  const { isAdmin } = useAuth();

  const closeAll = () => {
    setOpen(false);
    setChildrenDrawerStatus(initialStatus);
  };

  //when url changes, close Mobile menu
  useEffect(() => {
    closeAll();
  }, [pathname]);

  /** JSX */
  const menuFooter = (
    <SMenuFooter>
      {/* Dark & Light mode */}
      <Switch
        unCheckedChildren={<BsSun />}
        checkedChildren={<BsMoonStars />}
        onClick={changeTheme}
        checked={dark}
      />
      {/* Logout button */}
      <Button onClick={signOut}>
        <FiLogOut /> 登出
      </Button>
    </SMenuFooter>
  );

  /** EVENT HANDLERS */
  const openMenu = () => {
    setOpen(true);
  };

  const closeMenu = () => {
    setOpen(false);
  };

  const openChildDrawer = (id: string) => {
    setChildrenDrawerStatus((prev) => ({
      ...prev,
      [id]: true,
    }));
  };

  const childrenDrawers = [
    {
      id: "account",
      title: "帳戶",
      content: <UserAccount isMobile={true} />,
      icon: <FaUserCircle />,
    },
    {
      id: "shortcut",
      title: "快速捷徑",
      content: <Shortcut isMobile={true} />,
      icon: <BsLightningChargeFill />,
    },
    {
      id: "service",
      title: "服務選單",
      content: <Menu isMobile={true} onItemClick={closeAll} />,
      icon: <CgMenuGridR />,
    },
  ];

  /* ------------------ Render ------------------ */
  return (
    <>
      <SMobileMenuButton onClick={openMenu}>
        <AiFillCaretDown />
      </SMobileMenuButton>
      <Drawer
        open={open}
        push={false}
        onClose={closeMenu}
        closeIcon={<AiOutlineClose />}
        width={xs ? "100vw" : "375px"}
        headerStyle={{ padding: "1rem" }}
        bodyStyle={{ padding: 0 }}
        footer={menuFooter}
      >
        {isAdmin ? (
          <SBox>
            <FunctionButton />
            <ProgressBar />
          </SBox>
        ) : null}

        <List
          size="large"
          dataSource={childrenDrawers}
          renderItem={(item) => (
            <SListItem
              className="cursor-pointer"
              extra={<AiOutlineRight />}
              onClick={() => {
                openChildDrawer(item.id);
              }}
            >
              <Space>
                {item.icon} {item.title}
              </Space>
            </SListItem>
          )}
        />

        {childrenDrawers.map((child) => (
          <Drawer
            key={child.id}
            title={child.title}
            open={childrenDrawerStatus[child.id]}
            closeIcon={<AiOutlineLeft />}
            onClose={() => setChildrenDrawerStatus(initialStatus)}
            width={xs ? "100vw" : "375px"}
            headerStyle={{ padding: "1rem" }}
            footer={menuFooter}
          >
            {child.content}
          </Drawer>
        ))}
      </Drawer>
    </>
  );
};

export default MobileMenu;

/* ------------- Styled Component ------------- */
const SMobileMenuButton = styled.button`
  border: none;
  background-color: transparent;
  font-size: 1.2rem;
  color: white;
  transition: color 0.3s;

  &:hover {
    color: ${(props) => props.theme.antd.colorPrimary};
  }
`;

const SBox = styled.div`
  max-width: 90%;
  margin: 1rem auto;
  padding: 1rem 0.5rem;
  display: grid;
  grid-template-columns: fit-content(20px) auto;
  align-items: center;
  background-color: #192a56;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
`;

const SListItem = styled(List.Item)`
  & * {
    font-size: 1rem;
  }

  &:hover {
    color: ${(props) => props.theme.antd.colorPrimary};
  }
`;

const SMenuFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
