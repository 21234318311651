import React, { useEffect, useState } from "react";

/** Bootstrap */
import { Container } from "react-bootstrap";

/** Components */
import FallbackSuspense from "../components/FallbackSuspense";
import Footer from "../components/Footer";
import Breadcrumbs from "../components/breadcrumb/Breadcrumbs";
import Header from "../components/header/Header";
import NewSidebar from "../components/newSidebar/NewSidebar";

/** Contexts */
import { SocketProvider } from "../contexts/SocketContext";

/** Data */
import { getMenuCollection } from "../components/newSidebar/data";

/** Hooks */
import useMedia from "../hooks/useMedia";

/** Plugins */
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import S from "./Root.module.scss";

/** Utils */
import { startIdleTimer, stopIdleTimer } from "@/utils/autoLogout";

/* ---------------- Code Starts --------------- */

const Root: React.FC = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true);

  /** retrieve data based on the current URL path segment */
  const { t } = useTranslation("common", { keyPrefix: "sidebar" });
  let { pathname } = useLocation();
  const key = pathname.split("/").filter((el) => !!el)[0];
  const menuItemsData = getMenuCollection(key, t);

  const openMenu = () => setCollapsed(false);
  const closeMenu = () => setCollapsed(true);
  const toggleSidebar = () => setCollapsed((prev) => !prev);

  const { sm, xs } = useMedia();

  /** to prevent body scrolling behavior */
  useEffect(() => {
    if (xs && !collapsed && menuItemsData) {
      (document.body as HTMLBodyElement).classList.add("overflow-hidden");
    } else {
      (document.body as HTMLBodyElement).classList.remove("overflow-hidden");
    }
  }, [xs, sm, collapsed, menuItemsData]);

  /** collapse sidebar in mobile view */
  useEffect(() => {
    if (xs) {
      setCollapsed(true);
    }
  }, [xs]);

  /** add idle listener */
  useEffect(() => {
    startIdleTimer();
    return () => stopIdleTimer();
  }, []);

  return (
    <div className={S.wrap}>
      <SocketProvider>
        {/* HEADER */}
        <Header
          toggleSidebar={toggleSidebar}
          noSidebar={menuItemsData === undefined}
        />
        {/* MAIN: Sidebar + Content */}
        <main className={classNames(S.main, { [S.mobile]: !sm })}>
          {/* SIDEBAR: if there is no menuItemsData, hide Sidebar */}
          {menuItemsData ? (
            <aside
              className={classNames(S.sidebar, {
                [S.close]: collapsed,
                [S.mobile]: xs,
              })}
            >
              <NewSidebar
                collapsed={collapsed}
                open={openMenu}
                close={closeMenu}
                menuItemsData={menuItemsData}
              />
            </aside>
          ) : null}

          {/* CONTENT */}
          <section
            className={classNames(S.content, {
              [S.shrink]: menuItemsData && !collapsed,
            })}
          >
            <React.Suspense
              fallback={<FallbackSuspense text="Loading Page ..." />}
            >
              <Container fluid>
                <Breadcrumbs />
                {children}
                <Outlet />
              </Container>
            </React.Suspense>
            <Footer />
          </section>
        </main>
      </SocketProvider>
    </div>
  );
};

export default Root;
