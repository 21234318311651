import * as React from "react";
import { Navigate } from "react-router-dom";

import useAuth from "../../hooks/useAuth";
interface GuestGuardType {
  children: React.ReactNode;
}

// For routes that can only be accessed by guest users
function GuestGuard({ children }: GuestGuardType) {
  const { isAuthenticated, isInitialized } = useAuth();

  if (isInitialized && isAuthenticated) {
    return <Navigate to="/" />;
  }

  /** show children page after initialized; otherwise, if user is authenticated, the children page will be displayed first and redirect to `/` after. */
  return <React.Fragment>{isInitialized && children}</React.Fragment>;
}

export default GuestGuard;
