/** Create custom media breakpoint monitor. We don't use the Antd Grid.useBreakpoint, because it add new eventListener for each hook and may decrease performance. */

import { OneOfValues } from "@/types/util";
import { createContext, ReactNode, useEffect, useState } from "react";

export const mediaSize = {
  xxs: 390,
  xs: 576,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1600,
};

/** Antd Media Spec */
const mediaStr = {
  /** At 576px, xs and sm will be both true */
  xxs: `(max-width: ${mediaSize.xxs}px)`,
  xs: `(max-width: ${mediaSize.xs}px)`,
  sm: `(min-width: ${mediaSize.sm}px)`,
  md: `(min-width: ${mediaSize.md}px)`,
  lg: `(min-width: ${mediaSize.lg}px)`,
  xl: `(min-width: ${mediaSize.xl}px)`,
  xxl: `(min-width: ${mediaSize.xxl}px)`,
} as const;

type mediaStrKey = keyof typeof mediaStr;

const getCurrentMedia = (query: OneOfValues<typeof mediaStr>): boolean =>
  window.matchMedia(query).matches;

type MediaState = {
  -readonly [K in mediaStrKey]: boolean;
};

const initialState = {
  xxs: getCurrentMedia(mediaStr.xxs),
  xs: getCurrentMedia(mediaStr.xs),
  sm: getCurrentMedia(mediaStr.sm),
  md: getCurrentMedia(mediaStr.md),
  lg: getCurrentMedia(mediaStr.lg),
  xl: getCurrentMedia(mediaStr.xl),
  xxl: getCurrentMedia(mediaStr.xxl),
};

const MediaContext = createContext<MediaState>(initialState);

function MediaProvider({ children }: { children: ReactNode }) {
  const [media, setMedia] = useState<MediaState>(initialState);

  useEffect(() => {
    const matchQueryLists = {} as any;

    Object.keys(mediaStr).forEach((size) => {
      const queryList = window.matchMedia(mediaStr[size as mediaStrKey]);

      const handleChange = (e: any) => {
        setMedia((prevState) => ({
          ...prevState,
          [size]: e.matches,
        }));
      };

      queryList.addEventListener("change", handleChange);
      matchQueryLists[size] = {
        queryList,
        handleChange,
      };
    });

    return () => {
      Object.keys(matchQueryLists).forEach((size) => {
        const { queryList, handleChange } = matchQueryLists[size];
        queryList.removeEventListener("change", handleChange);
      });
    };
  }, []);

  return (
    <MediaContext.Provider value={media}>{children}</MediaContext.Provider>
  );
}

export { MediaContext, MediaProvider };
