import { ApiService } from "../axios";

/** Constants */
import { LS_LANG_LOGIN } from "../../constants";

/** Types */
import { AuthUser } from "../../types/auth";
import { LANG_BACKEND_MAP } from "../../types/i18n";
import { AxiosResponse } from "axios";

/** Utils */
import logger from "../logger";
import { getKeyByValue } from "../data";

const endpoints = {
  auth: {
    url: "/api/auth",
    method: "post",
  },
  resetPasswordConfirm: {
    url: "/api/auth/reset-password",
    method: "put",
  },
  resetPasswordEmail: {
    url: "/api/auth/reset-password",
    method: "post",
  },
  signIn: {
    url: "/api/auth/sign-in",
    method: "post",
  },
  signOut: {
    url: "/api/auth/sign-out",
    method: "post",
  },
} as const;

type EndpointName = keyof typeof endpoints;

class AuthApi extends ApiService<EndpointName> {
  _endpoints = endpoints;

  async auth(): Promise<{
    isAuthenticated: boolean;
    user: AuthUser;
  }> {
    const response = await this.send("auth");
    if (response.ok) {
      /** get user info from response payload */
      return parseUserInfo(response);
    }
    return {
      isAuthenticated: false,
      user: null,
    };
  }

  resetPasswordConfirm = async (
    password: string,
    token: string | undefined,
    uid: string | null
  ) => {
    return await this.send("resetPasswordConfirm", {
      data: { password, token, uid },
    });
  };

  resetPasswordEmail = async (email: string) => {
    return await this.send("resetPasswordEmail", { data: { email } });
  };

  async signIn(
    email: string,
    password: string
  ): Promise<{
    isAuthenticated: boolean;
    user: AuthUser;
    errorMessage?: string;
  }> {
    /** Persist login locale to Backend */
    let locale;
    try {
      locale = getKeyByValue(
        window.localStorage.getItem(LS_LANG_LOGIN),
        LANG_BACKEND_MAP
      );
    } catch (e) {
      console.error(e);
    }

    /** Call API */
    const response = await this.send("signIn", {
      data: { email, password, locale },
    });
    if (response.ok) {
      /** Clear login page locale */
      try {
        window.localStorage.removeItem(LS_LANG_LOGIN);
      } catch (e) {
        console.error(e);
      }

      /** get user info from response payload */
      return parseUserInfo(response);
    }
    return {
      isAuthenticated: false,
      user: null,
      errorMessage: response?.data?.message,
    };
  }

  async signOut() {
    const response = await this.send("signOut");
    return response.ok;
  }
}

const parseUserInfo = (response: AxiosResponse) => {
  /** get user info from response payload */
  let { lang } = response.data;

  /** Frontend and Backend have differnet language-locale naming. This object convert server-side lang to client-side lang.
   */
  if (LANG_BACKEND_MAP.hasOwnProperty(lang)) {
    lang = LANG_BACKEND_MAP[lang as keyof typeof LANG_BACKEND_MAP];
  } else {
    logger.warn(`The server returned an unknown language: ${lang}`);
  }

  return {
    isAuthenticated: true,
    user: { ...response.data, lang },
  };
};

export default AuthApi;
