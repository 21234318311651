import { useEffect, useState } from "react";

/** Antd */
import { Menu, MenuProps } from "antd";

/** Hooks */
import useMedia from "../../hooks/useMedia";

/** Icons */
import { FiMenu } from "react-icons/fi";
import { TbLayoutSidebarLeftCollapse } from "react-icons/tb";

/** Plugins */
import styled from "styled-components";
import { useLocation } from "react-router-dom";

/* ------------------- Types ------------------ */
interface NewSidebarProps {
  collapsed: boolean;
  open: () => void;
  close: () => void;
  menuItemsData: MenuProps["items"];
}

/* ---------------- Code Starts --------------- */

const NewSidebar = ({
  collapsed,
  open,
  close,
  menuItemsData,
}: NewSidebarProps) => {
  const { md } = useMedia();

  const { pathname } = useLocation();
  const [current, setCurrent] = useState(pathname);

  /** make menu item active depending on current URL */
  useEffect(() => {
    if (current !== pathname) {
      setCurrent(pathname);
    }
  }, [pathname, current]);

  return (
    <SSidebar isMobile={!md}>
      <SMenuCloseBtn onClick={close}>
        <TbLayoutSidebarLeftCollapse />
      </SMenuCloseBtn>
      <Menu
        mode="inline"
        items={menuItemsData}
        style={{ border: "none", background: "transparent" }}
        onClick={(e) => setCurrent(e.key)}
        selectedKeys={[current]}
      />
      {md && collapsed ? (
        <SMenuOpenBtn onClick={open}>
          <FiMenu />
        </SMenuOpenBtn>
      ) : null}
    </SSidebar>
  );
};

export default NewSidebar;

/* ------------- Styled Components ------------ */
const SSidebar = styled.div<{ isMobile: boolean }>`
  height: 100%;
  width: ${(props) => (props.isMobile ? "100%" : "200px")};
  background-color: var(--bs-white);
  overflow-y: auto;
  border-right: 1px solid var(--bs-light);
`;

const SMenuCloseBtn = styled.button`
  padding: 1rem;
  border: none;
  background-color: transparent;
  transition: color 0.3s;
  font-size: 1.3rem;
  color: var(--bs-dark);

  &:hover {
    color: var(--bs-orange);
  }
`;

const SMenuOpenBtn = styled.div`
  font-size: 1.2rem;
  position: absolute;
  left: 100%;
  top: 1rem;
  z-index: 100;
  padding: 1rem 0.5rem;
  border-radius: 0 10px 10px 0;
  background-color: #293042;
  cursor: pointer;
  color: white;

  box-shadow: var(--bs-light) 0px 0px 0px 1px;
  transition: all 0.3s;

  &:hover {
    color: var(--bs-orange);
  }
`;
