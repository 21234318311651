/** `SIGNAL_IDLE`, `SIGNAL_LOGOUT`, `IDLE_TIME` 需要與 SSR html 的 javascript (base.module.js) 對齊 */
const SIGNAL_IDLE = "sara-idle";
const SIGNAL_LOGOUT = "sara-logout";
const IDLE_TIME = 3600 * 1000; // milliseconds
let timeoutId: number;
let lastActiveTime: number = Date.now();

function logout(force: boolean) {
  const unixTime = parseInt(localStorage.getItem(SIGNAL_IDLE) ?? "");
  if (force || unixTime + IDLE_TIME <= Date.now()) {
    console.log("all tabs are idle");
    localStorage.setItem(SIGNAL_LOGOUT, String(Date.now()));
    window.location.href = "/logout";

    if (!force) {
      alert(
        `You have been logged out due to ${
          IDLE_TIME / 1000 / 60
        } minutes of inactivity.`
      );
    }
  } else {
    console.log("other tabs are active");
    resetTimer(false);
  }
}

function resetTimer(update = true) {
  if (update) {
    lastActiveTime = Date.now(); // update global variable (variable scope to current tab)
    localStorage.setItem(SIGNAL_IDLE, String(lastActiveTime));
  }
  clearTimeout(timeoutId);
  timeoutId = setTimeout(logout, IDLE_TIME);
}

function eventHandler() {
  resetTimer();
}

export const startIdleTimer = function () {
  window.addEventListener("storage", function (event) {
    if (event.key === SIGNAL_LOGOUT) {
      console.log("logout from other tab");
      logout(true);
    }
  });

  window.addEventListener("load", eventHandler);

  // DOM Events
  window.addEventListener("mousemove", eventHandler);
  window.addEventListener("mousedown", eventHandler); // catches touchscreen presses as well
  window.addEventListener("touchstart", eventHandler); // catches touchscreen swipes as well
  window.addEventListener("touchmove", eventHandler); // required by some devices
  window.addEventListener("click", eventHandler); // catches touchpad clicks as well
  window.addEventListener("keydown", eventHandler);
  window.addEventListener("scroll", eventHandler, true);
};

export const stopIdleTimer = function () {
  window.removeEventListener("load", eventHandler);
  window.removeEventListener("mousemove", eventHandler);
  window.removeEventListener("mousedown", eventHandler);
  window.removeEventListener("touchstart", eventHandler);
  window.removeEventListener("touchmove", eventHandler);
  window.removeEventListener("click", eventHandler);
  window.removeEventListener("keydown", eventHandler);
  window.removeEventListener("scroll", eventHandler, true);
  clearTimeout(timeoutId);
};

export { lastActiveTime };
