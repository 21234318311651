import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";

/** Reducers */
import notifReducer from "./slices/notification";
import dashboardReducer from "./slices/dashboard";

/** Store */
export const store = configureStore({
  reducer: {
    notification: notifReducer,
    dashboard: dashboardReducer,
  },
});

/** Typing */
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
