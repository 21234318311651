import { createContext, ReactNode, useEffect, useState } from "react";

/** Plugins */
import io, { Socket } from "socket.io-client";

/** Hooks */
import useAuth from "../hooks/useAuth";

/** Utils */
import logger from "../utils/logger";

/** Compenents */
import ProcessMessage from "../components/socket/ProcessMessage";

/** ---------- Code Start ---------- */

const SocketContext = createContext<Socket | undefined>(undefined);

function SocketProvider({ children }: { children: ReactNode }) {
  const [socket, setSocket] = useState<Socket | undefined>();
  const { user, isAdmin } = useAuth();

  useEffect(() => {
    const connect = () => {
      if (!isAdmin) return;

      logger.debug("socket connecting...");
      const socketIO = io();

      socketIO.on("connect", () => {
        logger.debug("socket online.");
        setSocket(socketIO);
      });

      socketIO.on("disconnect", () => {
        logger.debug("socket offline.");
        setSocket(undefined);
      });

      return socketIO;
    };

    const socket = connect();

    return () => {
      if (socket) {
        socket.close();
        logger.debug("socket disconnected due to user changed.");
      }
    };
  }, [user?.role, isAdmin]);

  return (
    <SocketContext.Provider value={socket}>
      <ProcessMessage />
      {children}
    </SocketContext.Provider>
  );
}

export { SocketContext, SocketProvider };
