import { useState } from "react";

/** Antd */
import { Col, Divider, Popover, Row } from "antd";

/** Constants */
import { ID_TOUR_HEADER_MENU } from "../../constants";

/** Icons */
import { CgMenuGridR } from "react-icons/cg";
import {
  FaCalendarCheck,
  FaThLarge,
  FaGlobe,
  FaProjectDiagram,
  FaChartLine,
  FaStepBackward,
  FaList,
  FaDatabase,
  FaExternalLinkAlt,
} from "react-icons/fa";

/** Hooks */
import useAuth from "../../hooks/useAuth";

/** Plugins */
import styled from "styled-components";
import { Link } from "react-router-dom";

/* ------------------- Types ------------------ */
interface MenuItem {
  subtitle: string;
  path: string;
  icon: JSX.Element;
  plan: Array<"Standard" | "Premium">;
  adminOnly: boolean;
  csr?: boolean;
}

interface MenuCategory {
  title: string;
  colorClass: string;
  items: MenuItem[];
}

type MenuData = Array<MenuCategory>;

/* ---------------- Code starts --------------- */

const Menu = ({
  isMobile,
  onItemClick,
}: {
  isMobile?: boolean;
  onItemClick?: Function;
}) => {
  const { isAdmin } = useAuth();
  const [open, setOpen] = useState(false);

  const popoverContent = (
    <SMenuContent>
      {menuData.map((category, idx) => {
        const { items, colorClass, title } = category;
        const availableItems = items.filter((item) =>
          isAdmin ? item : !item.adminOnly
        );
        const noAvailableItem = availableItems.length === 0;

        return (
          <div key={idx}>
            {noAvailableItem ? null : (
              <Divider
                orientation="left"
                style={{ color: "var(--bs-gray)" }}
                plain
              >
                {title}
              </Divider>
            )}

            <Row gutter={[0, 8]}>
              {availableItems.map((item, idx) => {
                const SMenuItemProps = item.csr
                  ? { to: item.path }
                  : { as: "a", href: item.path };

                return (
                  <Col key={idx} md={8} sm={12} xs={24}>
                    <SMenuItem
                      onClick={() => {
                        setOpen(false);
                        onItemClick && onItemClick();
                      }}
                      {...(SMenuItemProps as any)}
                    >
                      <span className={`icon ${colorClass}`}>{item.icon}</span>
                      <span className="text"> {item.subtitle}</span>
                    </SMenuItem>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      })}
    </SMenuContent>
  );

  if (isMobile) return popoverContent;

  return (
    <SMenu id={ID_TOUR_HEADER_MENU}>
      <Popover
        trigger="hover"
        content={popoverContent}
        open={open}
        onOpenChange={() => setOpen((p) => !p)}
      >
        <button className="menuBtn">
          <CgMenuGridR />
          <span>功能選單</span>
        </button>
      </Popover>
    </SMenu>
  );
};

export default Menu;

/* ------------- Styled Components ------------ */

const SMenu = styled.div`
  white-space: nowrap;

  .menuBtn {
    color: #fff;
    border: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    gap: 0.2rem;
    transition: color 0.3s;

    &:hover {
      color: var(--bs-orange);
    }
  }
`;

const SMenuContent = styled.div`
  min-width: 40vw;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const SMenuItem = styled(Link)`
  color: currentColor;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 4px;

  &:hover {
    color: currentColor;
    background-color: #cbcbcb1e;
    text-decoration: none;
  }

  .icon {
    font-size: 1.2rem;
    width: 2.5rem;
    height: 2.5rem;
    display: grid;
    place-content: center;
    border-radius: 4px;
    box-shadow: rgba(50, 50, 93, 0.2) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  }
  .text {
  }
`;

/* ----------------- Menu Data ---------------- */
const menuData: MenuData = [
  {
    title: "管理介面",
    colorClass: "text-info",
    items: [
      {
        subtitle: "資料庫管理",
        path: "/customer/data",
        icon: <FaDatabase />,
        plan: ["Premium"],
        adminOnly: true,
      },
      {
        subtitle: "專案管理",
        path: "/project",
        icon: <FaList />,
        plan: ["Standard", "Premium"],
        adminOnly: true,
        csr: true,
      },
      {
        subtitle: "站點管理",
        path: "/workcenter/manage",
        icon: <FaThLarge />,
        plan: ["Standard", "Premium"],
        adminOnly: true,
      },
      {
        subtitle: "製程管理",
        path: "/process",
        icon: <FaProjectDiagram />,
        plan: ["Standard", "Premium"],
        adminOnly: true,
        csr: true,
      },
      {
        subtitle: "資源管理",
        path: "/resource/manage",
        icon: <FaGlobe />,
        plan: ["Standard", "Premium"],
        adminOnly: true,
      },
    ],
  },
  {
    title: "排程看板",
    colorClass: "text-warning",
    items: [
      {
        subtitle: "站點排程",
        path: "/workcenter/schedule",
        icon: <FaThLarge />,
        plan: ["Standard", "Premium"],
        adminOnly: false,
        csr: true,
      },
      // {
      //   subtitle: "外包排程",
      //   path: "/workcenters-server-outsourcing",
      //   icon: <FaExternalLinkAlt />,
      //   plan: ["Premium"],
      //   adminOnly: false,
      // },
      {
        subtitle: "物料排程",
        path: "/material",
        icon: <FaCalendarCheck />,
        plan: ["Standard", "Premium"],
        adminOnly: true,
      },
    ],
  },
  {
    title: "報表紀錄",
    colorClass: "text-secondary",
    items: [
      {
        subtitle: "績效報表",
        path: "/report",
        icon: <FaChartLine />,
        plan: ["Standard", "Premium"],
        adminOnly: true,
        csr: true,
      },
      {
        subtitle: "版次紀錄",
        path: "/versions",
        icon: <FaStepBackward />,
        plan: ["Standard", "Premium"],
        adminOnly: true,
        csr: true,
      },
    ],
  },
];
