import "react-app-polyfill/stable";

import React, { lazy } from "react";
import ReactDOM from "react-dom";
import { ErrorBoundary } from "react-error-boundary";

/** Components */
import App from "./App";
import FallbackSuspense from "./components/FallbackSuspense";
import FallbackError from "./components/FallbackError";

/** Stylesheets */
import "./assets/antd/overwrite.scss";
import "./assets/react/dev.scss";
import "./assets/react/font.scss";
import "./assets/react/app.scss";

/** i18n */
import "./utils/i18n";

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<FallbackError text="App is not available." />}>
      <React.Suspense fallback={<FallbackSuspense text="Loading SARA ..." />}>
        <App />
      </React.Suspense>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);
