import React from "react";

/** Plugins */
import styled from "styled-components";

const Footer: React.FC = () => {
  return (
    <SFooter>
      <p className="m-0">
        &copy; {new Date().getFullYear()} -{" "}
        <span className="text-muted">InterAgent Co., Ltd.</span>
      </p>
    </SFooter>
  );
};

export default Footer;

/* ------------- Styled Components ------------ */
const SFooter = styled.footer`
  border-top: 2px solid var(--bs-light);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`;
